import { numberToNumberSpecialQuestions, numberToStringQuestions } from "./numbersToStringQuestions";

const DOT = '.';
const validIntChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Tab", "Backspace", "Shift", "CapsLock", " ", "ArrowDown", "ArrowUp", "ArrowLeft", "ArrowRight"];
const validNumberChars = [DOT, ...validIntChars];
const validNumberSpecialChars = ['/', '-'];

export const actAsNumberEvent = (e) => {
  const { target, data } = e;
  const alreadyHasDot = (data === DOT) && target.value.includes(DOT);
  if (!validNumberChars.includes(data) || alreadyHasDot) {
    e.preventDefault();
    return false;
  }
  return true;
}

export const actAsIntEvent = (e) => {
  const { data } = e;
  if (!validIntChars.includes(data)) {
    e.preventDefault();
    return false;
  }
  return true;
}
export const actAsNumberSpecialEvent = (e) => {
  const { data } = e;
  const isValidSpecialChar = validNumberChars.includes(data) || validNumberSpecialChars.includes(data);
  if (!isValidSpecialChar) {
    e.preventDefault();
    return false;
  }
  return true;
}
const actAsStringEvent = () => {}

export const actAsSignedNumberEvent = (e) => {
  const { target, data, inputType } = e;
  if (validIntChars.includes(data) || inputType === 'deleteContentBackward') {
    return true;
  }

  const regex = /^-?\d*\.?\d*$/;
  const newValue = target.value + data;

  if (!regex.test(newValue)) {
    e.preventDefault();
    return false;
  }

  return true;
};

export const getEventFunction = ({ id, allowNegatives }) => {
  if (allowNegatives) return actAsSignedNumberEvent;
  const isString = numberToStringQuestions.includes(id);
  const isSpecial = numberToNumberSpecialQuestions.includes(id);
  if (isString) return actAsStringEvent;
  if (isSpecial) return actAsNumberSpecialEvent;
  return actAsNumberEvent;
};